import React from 'react';
import Logo from '../assets/logo.png';
import './NavHeader.css';

type Props = {};
const arrowLeftIcon = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4 12L12 20L13.502 18.496L8.001 13H20V11H7.999L13.499 5.5L12 4L4 12Z"
            fill="#1E0A3C"
        />
    </svg>
);

export const NavHeader: React.FC<Props> = () => {
    return (
        <nav className="global-nav">
            <ul className="global-nav__items">
                <li className="logo-item">
                    <a href="https://www.marmaladedesignsystem.com/">
                        <img className="logo" src={Logo} alt="Eventbrite logo" />
                    </a>
                </li>
                <li className="components-item">
                    <a href="https://www.marmaladedesignsystem.com/">
                        <span className="global-nav__backlink-text">
                            {arrowLeftIcon} Back to Marmalade HQ
                        </span>
                    </a>
                </li>
            </ul>
        </nav>
    );
};
