import React from 'react';
import Marquee from 'react-fast-marquee';
import './Ticker.css';

type Props = {};

export const Ticker: React.FC<Props> = () => (
    <div className="ticker-banner">
        <Marquee className="ticket-banner__text" pauseOnHover={true} gradient={false}>
            <span>BUY MARMALADE yes so good want BUY MARMALADE yes so good want </span>
        </Marquee>
    </div>
);
