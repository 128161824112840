import React from 'react';
import { Hero } from './components/Hero';
import { ExampleSection } from './components/ExampleSection';
import { NavHeader } from './components/NavHeader';
import { Ticker } from './components/Ticker';
import blob from './assets/blob_vector.png';
import bottomSection from './assets/bottom_section.png';

import './App.css';

function App() {
    return (
        <div className="app">
            <NavHeader />
            <Hero />
            <ExampleSection />
            <img src={blob} style={{ width: '100vw' }} alt="" />
            <img
                src={bottomSection}
                style={{ width: '100vw', marginTop: '-10px', marginBottom: '-10px' }}
                alt=""
            />
            <Ticker />
        </div>
    );
}

export default App;
