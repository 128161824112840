import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import content from '../assets/content.png'
import './Hero.css';

type Props = {};

export const Hero: React.FC<Props> = () => (
    <div className="hero">
        <Player
            src="https://assets9.lottiefiles.com/packages/lf20_v4j6seqt.json"
            background="transparent"
            className="hero__background"
            speed={1}
            loop
            controls
            autoplay
        ></Player>
        <div className="hero__showcase">
            {/* <div className="hero__heading"> */}
                <img src={content} alt="Welcome to forms!" className="img"></img>
            {/* </div> */}
        </div>
        <div className="marker"></div>
    </div>
);
