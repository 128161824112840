import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import './ExampleSection.css';

type Props = {};

export const ExampleSection: React.FC<Props> = () => (
    <section className="form-example-section">
        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInBottomLeft">
            <div className="form-examples">
                <div className="input-fields">
                    <div className="input-field">
                        <span className="label">Label</span>
                    </div>
                    <div className="input-field-2">
                        <span className="label">Label</span>
                    </div>
                    <div className="input-field-3">
                        <span className="label">Label</span>
                    </div>
                    <div className="button">
                        <span className="button__label">Button</span>
                    </div>
                </div>
            </div>
        </AnimationOnScroll>
        
        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn">
            <div className="section-info">
                <span className="title">This is a placeholder section title.</span>
                <span className="description">
                    Wow, much forms. Very good. Lorem ipsum dolor form amet. Donec rutrum, neque vitae
                    facilisis tincidunt, tortor arcu tempus neque, at egestas diam inputs eu metus.
                    Donec sem ipsum, vulputate non quis, efficitur vel nisi form fields.
                </span>
            </div>
        </AnimationOnScroll>

        <span className="section-counter">one of one</span>
    </section>
);
